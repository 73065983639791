.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  font-weight: 200;
}

.cycle {
  animation: pre-cycle 2s 1, colorchange 60s ease-in-out 2s infinite;
  -webkit-animation: pre-cycle 2s 1, colorchange 60s 2s ease-in-out infinite;
}

@keyframes pre-cycle
{
  from   {background: #212121;}
  to {background: #2196f3;}
}

@keyframes colorchange
{
  0%   {background: #2196f3;}
  25%  {background: #673ab7;}
  45%  {background: #3f51b5;}
  65%  {background: #009688;}
  85% {background: #9c27b0;}
  100% {background: #2196f3;}
}

@-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */
{
  0%   {background: #2196f3;}
  25%  {background: #673ab7;}
  45%  {background: #3f51b5;}
  65%  {background: #009688;}
  85% {background: #9c27b0;}
  100% {background: #2196f3;}
}